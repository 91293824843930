@mixin small {
  @media only screen and (min-width: 600px) {
    @content;
  }
}

@mixin medium {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}

@mixin large {
  @media only screen and (min-width: 1440px) {
    @content;
  }
}


.skills-section {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: left;
  background-color: rgb(58, 70, 88);
  font-family: "Open Sans", sans-serif;
  font-style: bold;
  width: 100vw;
  padding: 15vh 0;
  box-sizing: border-box;
  
}
.skill-title{
  justify-self: start;
  text-align: center;
  font-size: 1.8em;
  @include small{
    font-size: 3em;
  }
  margin: 16px;
}