@mixin small {
@media only screen and (min-width: 600px) {
    @content;
}
}

@mixin medium {
@media only screen and (min-width: 1024px) {
    @content;
}
}

@mixin large {
@media only screen and (min-width: 1440px) {
    @content;
}
}
.contact-section {
    position:relative;
    background-color: #597BBE;
   
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "psychic" "contactform";
    
    width: 100vw;
    
    box-sizing: border-box;
    @include small {
        grid-template-areas: "psychic contactform";
        grid-template-rows: 1fr;
        grid-template-columns: 2fr 3fr;
    }
    @include medium {
        padding: 0;
    }
    
}

.psychic-area{
    position: relative;
    justify-self: center;
    align-self: end;
    padding: 0 0 0 10%;
    width: 50%;
    @include small{
        justify-self: end;
        grid-area: psychic;
        padding: 0 10%;
        width: 60%;
        align-self: center;
    }
}
.contact-area{
    display: flex;
    align-items: start;
    justify-content: center;

    height: 100%;
    grid-area: contactform;
    width: 100%;
    box-sizing: border-box;
    padding:  10%;
    @include small{
     padding:20%;
     align-items: center;
    }
    align-self: center;

    justify-self:center;
}
