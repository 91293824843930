@mixin small {
  @media only screen and (min-width: 600px) {
    @content;
  }
}

@mixin medium {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}

@mixin large {
  @media only screen and (min-width: 1440px) {
    @content;
  }
}


.home-section {
  text-align: left;
  background-color: #393939;
  font-family: "Open Sans", sans-serif;
  font-style: bold;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "face" "introduction";

  width: 100vw;
  padding: 15vh 0;
  box-sizing: border-box;
  @include small {
    grid-template-areas: "face introduction";
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
  }
  @include medium {
    padding: 0;
  }
}
.introduction-area {
  grid-area: introduction;
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 0 10%;
  font-size:24px;
  text-align: center;
  align-self: start;
  @include small{
    align-self:center;
    text-align: left;
  }
  @include medium{
   
    font-size: 64px;
  }
  .description {
    display: block;
    color: #DDD;
    justify-self: center;
    align-self: center;
    @include small{
        align-self: start;
    }
    margin-bottom: 8px;
  }
  .cooltext {
    grid-area: cooltext;
    background-color: rgb(31, 32, 32);
    color: white;
    justify-content: center;
    align-self: center;  
    
    @include small{
        align-self: start;
    }
    margin-top: 8px;
  }
}
.picture-area {
  grid-area: face;
  padding: 0 10%;
  align-self: end;
  @include small{
    align-self: center;
    padding: 0 15%;
  }
  
}
