
@font-face {
  font-family: 'FlippsRegular';
  src: url('../../res/fonts/Fipps-Regular.otf') format('truetype'); 
}
.my-picture {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.my-face {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(0.66, 0.66) rotate(4deg) translate(16%, -8%);
}

.my-frame {
  display: block;
  position: relative;
  z-index: 2;
  width: 100%;
}
.my-name {
  position: absolute;
  display: block;
  width:    100%;
  color: #333333;
  z-index: 3;
  bottom: 17%;
  transform-origin: 50% 50%;
  transform: rotate(4deg) translate(11%) scale(0.60,0.60);
  font-family: 'FlippsRegular', cursive;


}

