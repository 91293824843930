@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css");
html{
  overscroll-behavior-y: none;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  background-color: black;
  
}
.app::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.app {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
a , a:visited{
  text-decoration: none;
  color: inherit;
}
.scroll-animation{
  position: fixed;
  bottom: 4vh;
  left: 50%;
  transform: translate(-50%, 0%);
  height: 10vh;
  width: 10vh;
}
.app {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: scroll;
  max-height: 100vh;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  
}
.app section{
  scroll-snap-align: center;
  height: 100vh;
  overflow: hidden;
}
/* @import url('https://fonts.googleapis.com/css?family=Literata:400,400i,500,500i,600,600i,700,700i&display=swap'); */
/* @import url('https://fonts.googleapis.com/css?family=Neuton:200,300,400,400i,700,800&display=swap'); */

