@font-face {
  font-family: "YoutubeStar";
  src: url("../../res/fonts/youtube-star.ttf") format("truetype");
}
@media(max-width: 500px){
  .mobile-hidden{
    display: none;
  }
  .mobile-show{
    display: block;
  }
}
@media(min-width:500px){
  .mobile-show{
    display: none;
  }
}


.hanging-sign {
  position: relative;
  height: fit-content;
  max-height: 500px;
  width: 100%;
  img {
    height: 500px;
    object-fit: cover;
    width: clamp(32px, 10%, 200px);
    position: absolute;
    &.right-rope {
      bottom: 100%;
      right: 15%;
    }
    &.left-rope {
      bottom: 100%;
      left: 15%;
    }
  }
  .sign {
    height: fit-content;
    width: 100%;
    background-color: white;
    bottom: 0;
    border-radius: 16px;
    border-style: solid;
    border-color: #555;
    border-width: 10px;
    @media (min-width:  600px) {
      padding: 32px;
    }
    padding: 24px;
    box-sizing: border-box;
    .sign-title {
      text-align: center;
      text-transform: uppercase;
      font-family: "YoutubeStar";
      font-size: 1.5em;
      @media (min-width: 1366px) {
        font-size: 3em;
      }

      color: #d65636;
    }
    p {
      color: #333;
      @media (min-width: 1366px) {
        font-size: 2.5vh;
        line-height: 1.2em;
        text-align: justify;
      }
      font-size: 0.55em;
      text-align: center;
      line-height: 1.2em;
      margin: 0.5em 0;
    }
    .about-buttons{
      
      display: flex;
      flex-direction: row;
      justify-content: center;
      
      padding-top: 16px;
      div {
        width: fit-content;
        border-radius: 4px;
        padding: 10px;
        margin: 16px;
        cursor: pointer;
        color:white;
        font-size: 0.6em;
        
        @media (min-width: 1366px) {
          font-size: 0.8em;
        }
        transition: all 1s;
      }
      div:hover{
        transform: scale(1.1);
      }
      .projects-button{
        background-color: #ef476f;
      }
      .resume-button{
        background-color: #073b4c;
      }
      .contact-button{
        background-color: #90be6d;
      }

    }
    
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2021-3-11 1:31:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-2%);
    transform: translateX(-2%);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(2%);
    transform: translateX(2%);
  }
  80% {
    -webkit-transform: translateX(1.6%);
    transform: translateX(1.6%);
  }
  90% {
    -webkit-transform: translateX(-1.6%);
    transform: translateX(-1.6%);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-2%);
    transform: translateX(-2%);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(2%);
    transform: translateX(2%);
  }
  80% {
    -webkit-transform: translateX(1.6%);
    transform: translateX(1.6%);
  }
  90% {
    -webkit-transform: translateX(-1.6%);
    transform: translateX(-1.6%);
  }
}

.dissappear {
  -webkit-animation: slide-out-top 2s linear both;
  animation: slide-out-top 2s linear both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-3-11 1:4:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 1;
  }
}
@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 1;
  }
}

.appear {
  -webkit-animation: bounce-in-top 1.1s cubic-bezier(0.755, 0.05, 0.855, 0.06)
    backwards;
  animation: bounce-in-top 1.1s cubic-bezier(0.755, 0.05, 0.855, 0.06) backwards;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-3-10 23:28:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-top
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-100vh);
    transform: translateY(-100vh);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;

  }
}
@keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-100vh);
    transform: translateY(-100vh);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
