
 
@mixin small {
  @media only screen and (min-width: 600px) {
      @content;
  }
  }
  
  @mixin medium {
  @media only screen and (min-width: 1024px) {
      @content;
  }
  }
  
  @mixin large {
  @media only screen and (min-width: 1000px) {
      @content;
  }
  }
  .projects-section {
    display: grid;
    position:relative;
    background-color: #333;
     
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "projects";
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    @include small {
        padding:0;
    }
    @include large {
        grid-template-areas: "working projects";
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
    }
      
  }
    
    .working-area{
        height: 100%;
        position: absolute;
        display: none;
        top:0;
        right:0;
        width: 20%;
        @include large{
            display: inline;
            position: relative;
            grid-area: working;
            width: 90%;
            
            align-self: center;
        }
    }
    .projects-area{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        grid-area: projects;
        width: 100%;
        box-sizing: border-box;
        padding:0;
        @include small {
            padding: 10%;
        }
        align-self: center;
        justify-self:center;
    }
    