form {
  color: #102a43;
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  background-color: #ffffff;
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;
  h2 {
    font-weight: 500;
    font-size: 5em;
    color: rgb(68, 68, 68);
    @media (max-width: 800px) {
      font-size: 2.8em;
    }
    text-align: center;
    margin-bottom: 24px;
  }
  p {
    line-height: 1.3em;
    font-size: 1.4em;
    margin-top: 16px;
    text-align:justify;
    color: #222;
    @media (max-width: 800px) {
      line-height: 20px;
      font-size: 1em;
    }
    a {
      font-weight: 700;
      white-space: nowrap;
    }
    a.linkedin {
      color:  #0e76a8 ;
    }
    a.beer {
      color:#f0e130;
    }
    a.email{
      color: #BB001B;
    }
  }
  .contact-buttons{
    margin-top: 16px;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    @media (max-width: 800px) {
      font-size: 0.9em;
      margin-top: 10px;
    }
    font-size: 2em;
    a {
      padding: 8px 16px;
      color:white;
      border-radius: 6px;
      white-space: nowrap;
    }
    a.linkedin-button{
      background-color:  #0e76a8 ;
    }
    a.email-button{
      background-color:  #BB001B;
    }
  }
}