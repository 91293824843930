@mixin small {
    @media only screen and (min-width: 600px) {
      @content;
    }
  }
  
  @mixin medium {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  }
  
  @mixin large {
    @media only screen and (min-width: 1440px) {
      @content;
    }
  }
.skill-widget {
  display: grid;
  @include small{
      justify-content: center;
  }
  grid-template-rows: auto auto;
  box-sizing: border-box;
 
  grid-auto-flow: column;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  justify-content: left;
  height: 60;
}
.skill-widget::-webkit-scrollbar {
  display: none;
}

.skill-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  color: #333;
 
  padding: 16px;
  height: fit-content;
  margin: 8px;
  width: 80px;
  font-size: 0.8em;
  visibility: hidden;
  &.visible{
    visibility: visible;
    -webkit-animation: flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  @include medium{
      margin: 16px;
      font-size: 0.9em;
      width: 10vw;
  }  
  box-sizing: border-box;
  p {
    margin-top: 16px;
   
    font-weight: 700;
    text-align: center;
  }
  
  .skill-image-container {
    position: relative;
    width: 90%; /* The size you want */
  }
  .skill-image-container:after {
    content: "";
    display: block;
    padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
  }

  .skill-image-container img {
    position: absolute; /* Take your picture out of the flow */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; /* Make the picture taking the size of it's parent */
    width: 100%; /* This if for the object-fit */
    height: 100%; /* This if for the object-fit */
    object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
    object-position: center;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-3-16 18:4:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-in-ver-right
 * ----------------------------------------
 */
 @-webkit-keyframes flip-in-ver-right {
    0% {
      -webkit-transform: rotateY(-80deg);
              transform: rotateY(-80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      opacity: 1;
    }
  }
  @keyframes flip-in-ver-right {
    0% {
      -webkit-transform: rotateY(-80deg);
              transform: rotateY(-80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      opacity: 1;
    }
  }
  